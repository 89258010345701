/* can use app and index to override larger layout things on page */
.App {
  text-align: center;
}

.max-toolbar {
  max-width: 2600px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.logo-wide {
  margin: 0;
}

@media (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .logo-wide {
    width: 312px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .logo-wide {
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
}
@media (min-width: 801px) and (max-width: 900px) {
}
@media (min-width: 901px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1366px) {
}

@media (min-width: 1367px) and (max-width: 1999px) {
}

@media (min-width: 2000px) and (max-width: 2082px) {
}

@media (min-width: 2083px) {
}
