.content-container {
  width: 1292px;
  margin: 0 auto;
}

button {
  padding: 0px !important;
}

@media (max-width: 575px) {
  .content-container {
    width: 375px;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .content-container {
    /* margin-top: 66px; */
    width: 812px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .content-container {
    width: 1032px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .content-container {
    width: 1316px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .content-container {
    max-width: 1316px;
    margin: 0 auto;
  }
}
