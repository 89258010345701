.img-1 {
  width: 636px;
  height: 948px;
}

.img-2,
.img-3 {
  width: 636px;
  height: 468px;
}

@media (max-width: 767px) {
  .img-1 {
    width: 356px !important;
    height: 531px !important;
    margin-bottom: 10px;
  }
  .img-2,
  .img-3 {
    width: 356px !important;
    height: 262px !important;
    margin-bottom: 10px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .img-1 {
    width: 370px;
    height: 552px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 370px;
    height: 270px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2 {
    margin-bottom: 10px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) {
  .img-1 {
    width: 487px;
    height: 726px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 487px;
    height: 359px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2 {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .img-1 {
    width: 559px;
    height: 833px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 559px;
    height: 412px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1544px) {
  .img-1 {
    width: 636px;
    height: 948px;
  }
  .img-2,
  .img-3 {
    width: 636px;
    height: 468px;
  }
}
