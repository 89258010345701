[href="/artproject3-hunger-text"],
[href="/artproject2-white-pine-1-text"],
[href="/artproject3-photo-shoot-text"],
[href="/artproject2-white-pine-2-text"],
[href="/artproject2-california-rt43-text"],
[href="/artprojectwalls-of-arith-text"],
[href="/artproject2-ars-fab-text"],
[href="/artproject2-cirque-du-celeste-text"],
[href="/artproject3-numeral-2"],
[href="/artproject6-numeral3"] {
  pointer-events: none;
  cursor: default;
}

button {
  padding: 0px !important;
}

@media (max-width: 575px) {
  a[href="/artproject1-white-pine-art"] {
    order: 1;
  }
  a[href="/artproject2-white-pine-text"] {
    order: 2;
  }
  a[href="/artproject/4-photo-shoot-art"] {
    order: 3;
  }
  a[href="/artproject3-photo-shoot-text"] {
    order: 4;
  }
  a[href="/artproject/1-white-pine-2-art"] {
    order: 5;
  }
  a[href="/artproject2-white-pine-2-text"] {
    order: 6;
  }
  a[href="/artproject/4-hunger-art"] {
    order: 7;
  }
  a[href="/artproject3-hunger-text"] {
    order: 8;
  }
  a[href="/artproject/1-california-rt43-art"] {
    order: 9;
  }
  a[href="/artproject2-california-rt43-text"] {
    order: 10;
  }
  a[href="/artproject/4-walls-of-arith-art"] {
    order: 11;
  }
  a[href="/artprojectwalls-of-arith-text"] {
    order: 12;
  }
  a[href="/artproject/1-ars-fab-cover"] {
    order: 13;
  }
  a[href="/artproject2-ars-fab-text"] {
    order: 14;
  }
  a[href="/artproject/3-ars-fab-spread"] {
    order: 15;
  }
  a[href="/artproject/1-cirque-du-celeste-1-art"] {
    order: 16;
  }
  a[href="/artproject2-cirque-du-celeste-text"] {
    order: 17;
  }
  a[href="/artproject/4-cirque-du-celeste-2-art"] {
    order: 18;
  }
  a[href="/artproject3-numeral-2"] {
    order: 19;
  }
  a[href="/artproject/5-cirque-du-celeste-3-art"] {
    order: 20;
  }
  a[href="/artproject/5-cirque-du-celeste-3-art"] {
    order: 21;
  }
  a[href="/artproject6-numeral3"] {
    order: 22;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}
