.lg-img {
  /* margin-top: inherit; */
  display: block;
  margin: auto;
}

/* styles for mobile browsers smaller than 576px; (iPhone) */
@media (max-width: 575px) {
  [id="1-white-pine-art"] {
    width: 374px;
    height: 207px;
  }

  [id="4-photo-shoot-art"] {
    width: 374px;
    height: 297px;
  }

  [id="1-white-pine-2-art"] {
    width: 374px;
    height: 191px;
  }

  [id="4-hunger-art"] {
    width: 374px;
    height: 278px;
  }
  [id="1-california-rt43-art"] {
    width: 374px;
    height: 250px;
  }

  [id="4-walls-of-arith-art"] {
    width: 374px;
    height: 250px;
  }

  [id="1-ars-fab-cover"] {
    width: 374px;
    height: 257px;
  }

  [id="3-ars-fab-spread"] {
    width: 374px;
    height: 131px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 374px;
    height: 370px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 374px;
    height: 365px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 374px;
    height: 342px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  [id="1-white-pine-art"] {
    width: 671px;
    height: 372px;
  }

  [id="4-photo-shoot-art"] {
    width: 671px;
    height: 533px;
  }

  [id="1-white-pine-2-art"] {
    width: 671px;
    height: 342px;
  }

  [id="4-hunger-art"] {
    width: 671px;
    height: 498px;
  }
  [id="1-california-rt43-art"] {
    width: 671px;
    height: 449px;
  }

  [id="4-walls-of-arith-art"] {
    width: 671px;
    height: 449px;
  }

  [id="1-ars-fab-cover"] {
    width: 671px;
    height: 462px;
  }

  [id="3-ars-fab-spread"] {
    width: 671px;
    height: 235px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 671px;
    height: 663px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 671px;
    height: 656px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 671px;
    height: 614px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  [id="1-white-pine-art"] {
    width: 992px;
    height: 550px;
  }

  [id="4-photo-shoot-art"] {
    width: 992px;
    height: 787px;
  }

  [id="1-white-pine-2-art"] {
    width: 992px;
    height: 506px;
  }

  [id="4-hunger-art"] {
    width: 992px;
    height: 736px;
  }
  [id="1-california-rt43-art"] {
    width: 992px;
    height: 664px;
  }

  [id="4-walls-of-arith-art"] {
    width: 992px;
    height: 664px;
  }

  [id="1-ars-fab-cover"] {
    width: 992px;
    height: 683px;
  }

  [id="3-ars-fab-spread"] {
    width: 992px;
    height: 347px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 992px;
    height: 980px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 992px;
    height: 969px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 992px;
    height: 908px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  [id="1-white-pine-art"] {
    width: 1200px;
    height: 665px;
  }

  [id="4-photo-shoot-art"] {
    width: 1200px;
    height: 952px;
  }

  [id="1-white-pine-2-art"] {
    width: 1200px;
    height: 612px;
  }

  [id="4-hunger-art"] {
    width: 1200px;
    height: 891px;
  }
  [id="1-california-rt43-art"] {
    width: 1200px;
    height: 803px;
  }

  [id="4-walls-of-arith-art"] {
    width: 1200px;
    height: 804px;
  }

  [id="1-ars-fab-cover"] {
    width: 1200px;
    height: 826px;
  }

  [id="3-ars-fab-spread"] {
    width: 1200px;
    height: 420px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 1075px;
    height: 1062px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 1075px;
    height: 1051px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 1075px;
    height: 984px;
  }
}

@media (min-width: 1521px) {
  [id="1-white-pine-art"] {
    width: 1420px;
    height: 787px;
  }

  [id="4-photo-shoot-art"] {
    width: 1420px;
    height: 1127px;
  }

  [id="1-white-pine-2-art"] {
    width: 1420px;
    height: 724px;
  }

  [id="4-hunger-art"] {
    width: 1420px;
    height: 1054px;
  }
  [id="1-california-rt43-art"] {
    width: 1420px;
    height: 950px;
  }

  [id="4-walls-of-arith-art"] {
    width: 1420px;
    height: 951px;
  }

  [id="1-ars-fab-cover"] {
    width: 1420px;
    height: 977px;
  }

  [id="3-ars-fab-spread"] {
    width: 1420px;
    height: 497px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 1075px;
    height: 1062px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 1075px;
    height: 1051px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 1075px;
    height: 984px;
  }
}
