.btn-link {
  padding: 0 !important;
  color: gray !important;
  text-decoration: none !important;
  letter-spacing: 0.2rem;
  font-family: "Maven pro", sans-serif !important;
  font-size: 14pt !important;
}
.close {
  margin: 1px 10px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-link {
    font-family: "Maven pro", sans-serif !important;
    /* font-size: 14pt !important; */
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .btn-link {
    /* font-size: 14pt !important; */
    padding: 0;
    position: static !important;
  }
}

@media only screen and (min-width: 1025px) {
  /* styles for browsers larger than 1025px; */
}

@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .btn-link {
    color: gray !important;
    text-decoration: none !important;
    font-family: "Maven pro", sans-serif !important;
    /* font-size: 14pt !important; */
  }
}
