.side-drawer {
  height: 50%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200 !important;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateY(0);
}

.side-drawer ul {
  padding: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer a {
  font-family: "Maven Pro", sans-serif;
  font-size: 14pt;
  letter-spacing: 0.2rem;
  color: grey;
  text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: grey;
  text-decoration: none;
}

.side-drawer li {
  margin: 0.5rem 0;
  text-align: center;
}

button {
  text-decoration: none;
  border: 0px;
  background-color: white;
}

.closeX {
  position: absolute;
  right: 27px;
  top: -1px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.closeX:hover {
  opacity: 1;
}
.closeX:before,
.closeX:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.closeX:before {
  transform: rotate(45deg);
}
.closeX:after {
  transform: rotate(-45deg);
}

@media (min-width: 1025px) {
  .side-drawer {
    display: none;
  }
}
