#foo {
  width: 1282px;
  height: 468px;
}

.img-card {
  width: 636px;
  height: 468px;
}

@media (max-width: 575px) {
  /* styles for mobile browsers smaller than 576px; (iPhone) */
  #foo {
    width: 375px !important;
    height: 137px;
  }
  .img-card {
    width: 375px !important;
    height: 276px;
    margin-bottom: 10px !important;
    padding: 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #foo {
    width: 705px;
    height: 257px;
  }
  .img-card {
    margin-bottom: 10px !important;
    margin-right: 0px;
    padding: 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #foo {
    width: 722px;
    height: 263px;
  }
  .img-card {
    width: 356px;
    height: 262px;
    margin-bottom: 10px !important;
    margin-right: 10px;
    padding: 0px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #foo {
    width: 1000px;
    height: 365px;
  }

  .img-card {
    width: 487px;
    height: 359px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 0px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #foo {
    width: 1128px;
    height: 411px;
  }
  .img-card {
    width: 559px;
    height: 412px;
    margin-bottom: 10px;
    padding: 0px;
  }
}
/* keeping the grid from becoming more cols*/
@media (min-width: 1521px) {
  #foo {
    width: 1010px;
    height: 368px;
  }
  .img-card {
    width: 598px;
    height: 440px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

/* keeping the grid from becoming more cols*/
@media (min-width: 1544px) {
  #foo {
    width: 1282px;
    height: 468px;
  }
  .img-card {
    width: 636px;
    height: 468px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  #foo {
    width: 1282px;
    height: 468px;
    max-width: 1282px !important;
  }
  .img-card {
    width: 636px;
    height: 468px;
    max-width: 636px;
    margin-bottom: 10px;
    padding: 0px;
  }
}
