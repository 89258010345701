@import url(https://fonts.googleapis.com/css2?family=Maven+Pro&display=swap);
.btn-link {
  padding: 0 !important;
  color: gray !important;
  text-decoration: none !important;
  letter-spacing: 0.2rem;
  font-family: "Maven pro", sans-serif !important;
  font-size: 14pt !important;
}
.close {
  margin: 1px 10px !important;
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .btn-link {
    font-family: "Maven pro", sans-serif !important;
    /* font-size: 14pt !important; */
  }
}

@media only screen and (min-width: 300px) and (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .btn-link {
    /* font-size: 14pt !important; */
    padding: 0;
    position: static !important;
  }
}

@media only screen and (min-width: 1025px) {
  /* styles for browsers larger than 1025px; */
}

@media only screen and (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .btn-link {
    color: gray !important;
    text-decoration: none !important;
    font-family: "Maven pro", sans-serif !important;
    /* font-size: 14pt !important; */
  }
}

.side-drawer {
  height: 50%;
  background-color: white;
  box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 200 !important;
  transform: translateY(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer.open {
  transform: translateY(0);
}

.side-drawer ul {
  padding: 0;
  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.side-drawer a {
  font-family: "Maven Pro", sans-serif;
  font-size: 14pt;
  letter-spacing: 0.2rem;
  color: grey;
  text-decoration: none;
}

.side-drawer a:hover,
.side-drawer a:active {
  color: grey;
  text-decoration: none;
}

.side-drawer li {
  margin: 0.5rem 0;
  text-align: center;
}

button {
  text-decoration: none;
  border: 0px;
  background-color: white;
}

.closeX {
  position: absolute;
  right: 27px;
  top: -1px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
}
.closeX:hover {
  opacity: 1;
}
.closeX:before,
.closeX:after {
  position: absolute;
  left: 15px;
  content: " ";
  height: 33px;
  width: 2px;
  background-color: #333;
}
.closeX:before {
  transform: rotate(45deg);
}
.closeX:after {
  transform: rotate(-45deg);
}

@media (min-width: 1025px) {
  .side-drawer {
    display: none;
  }
}

.toggle-button {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 24px;
  width: 30px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  box-sizing: border-box;
}

.toggle-button:focus {
  outline: none;
}

.toggle-button_line {
  width: 30px;
  height: 2px;
  background: grey;
}

.toolbar {
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 103%;
  background-color: white;
  height: 66px;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar_nav-items a {
  color: grey;
  text-decoration: none;
}

.toolbar_nav-items ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.toolbar_nav-items li {
  padding: 0 1.2rem;
}

.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
  text-decoration: none;
  color: grey;
}

.spacer {
  flex: 1 1;
}

.design,
.art,
.info {
  font-family: "Maven Pro", sans-serif;
  font-size: 14pt;
  /* top: 12px; */
  letter-spacing: 0.2rem;
}

@media (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 255px !important;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 307px !important;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .toolbar_toggle-button {
    margin-left: 6px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 801px) and (max-width: 900px) {
  .toolbar_toggle-button {
    margin-left: 26px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 901px) and (max-width: 1024px) {
  .toolbar_toggle-button {
    margin-left: 4px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .toolbar {
    width: 90%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .toolbar_logo {
    margin-left: 0;
  }

  .design,
  .art,
  .info {
    font-family: "Maven Pro", sans-serif;
    font-size: 14pt;
    /* top: 12px; */
    letter-spacing: 0.2rem;
  }

  .m-logo {
    width: 592px;
    height: auto;
    margin-left: 7px;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1999px) {
  .toolbar {
    width: 84%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .toolbar_logo {
    margin-left: 0;
  }

  .design,
  .art,
  .info {
    font-family: "Maven Pro", sans-serif;
    font-size: 14pt;
    /* top: 12px; */
    letter-spacing: 0.2rem;
  }

  .m-logo {
    width: 592px;
    height: auto;
    margin-left: 7px;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 2000px) and (max-width: 2082px) {
  .toolbar {
    width: 85%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .m-logo {
    width: 636px;
    height: auto;
    margin-bottom: 0px !important;
    /* margin-left: 303px; */
  }
}

@media (min-width: 2083px) {
  .toolbar {
    max-width: 1404px;
  }
  .toolbar_nav-items {
    margin-left: 0px !important;
    margin-right: 27px;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .m-logo {
    width: 636px;
    height: auto;
    margin-bottom: 0px !important;
    margin-left: 10px;
  }
}

#foo {
  width: 1282px;
  height: 468px;
}

.img-card {
  width: 636px;
  height: 468px;
}

@media (max-width: 575px) {
  /* styles for mobile browsers smaller than 576px; (iPhone) */
  #foo {
    width: 375px !important;
    height: 137px;
  }
  .img-card {
    width: 375px !important;
    height: 276px;
    margin-bottom: 10px !important;
    padding: 0px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  #foo {
    width: 705px;
    height: 257px;
  }
  .img-card {
    margin-bottom: 10px !important;
    margin-right: 0px;
    padding: 0px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  #foo {
    width: 722px;
    height: 263px;
  }
  .img-card {
    width: 356px;
    height: 262px;
    margin-bottom: 10px !important;
    margin-right: 10px;
    padding: 0px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  #foo {
    width: 1000px;
    height: 365px;
  }

  .img-card {
    width: 487px;
    height: 359px;
    margin-bottom: 10px;
    margin-right: 10px;
    padding: 0px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  #foo {
    width: 1128px;
    height: 411px;
  }
  .img-card {
    width: 559px;
    height: 412px;
    margin-bottom: 10px;
    padding: 0px;
  }
}
/* keeping the grid from becoming more cols*/
@media (min-width: 1521px) {
  #foo {
    width: 1010px;
    height: 368px;
  }
  .img-card {
    width: 598px;
    height: 440px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

/* keeping the grid from becoming more cols*/
@media (min-width: 1544px) {
  #foo {
    width: 1282px;
    height: 468px;
  }
  .img-card {
    width: 636px;
    height: 468px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  #foo {
    width: 1282px;
    height: 468px;
    max-width: 1282px !important;
  }
  .img-card {
    width: 636px;
    height: 468px;
    max-width: 636px;
    margin-bottom: 10px;
    padding: 0px;
  }
}

.content-container {
  width: 1292px;
  margin: 0 auto;
}

button {
  padding: 0px !important;
}

@media (max-width: 575px) {
  .content-container {
    width: 375px;
    display: flex;
    /* flex-wrap: wrap; */
    flex-direction: column;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .content-container {
    /* margin-top: 66px; */
    width: 812px;
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .content-container {
    width: 1032px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .content-container {
    width: 1316px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .content-container {
    max-width: 1316px;
    margin: 0 auto;
  }
}

.full-w {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .full-w {
    object-fit: cover;
    overflow: hidden;
    width: 698px !important;
    /* height: 813px !important;
    object-position: 50% -20px; */
  }
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .full-w {
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .full-w {
    width: 100%;
    object-fit: cover;
    object-position: 12% -410px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1524px) {
  .full-w {
    width: 100%;
    object-position: 12% -530px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1698px) {
  .full-w {
    width: 100%;
    object-position: 12% -600px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2110px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -600px;
  }
}

@media only screen and (min-width: 2111px) and (max-width: 2456px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -700px;
  }
}

@media only screen and (min-width: 2457px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -750px;
  }
}

.img-1 {
  width: 636px;
  height: 948px;
}

.img-2,
.img-3 {
  width: 636px;
  height: 468px;
}

@media (max-width: 767px) {
  .img-1 {
    width: 356px !important;
    height: 531px !important;
    margin-bottom: 10px;
  }
  .img-2,
  .img-3 {
    width: 356px !important;
    height: 262px !important;
    margin-bottom: 10px;
  }
}

/* Medium devices (tablets, less than 992px) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .img-1 {
    width: 370px;
    height: 552px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 370px;
    height: 270px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2 {
    margin-bottom: 10px;
  }
}

/* Large devices (desktops, less than 1200px) */
@media (min-width: 992px) {
  .img-1 {
    width: 487px;
    height: 726px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 487px;
    height: 359px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2 {
    margin-bottom: 10px;
  }
}

@media (min-width: 1200px) {
  .img-1 {
    width: 559px;
    height: 833px;
    padding-left: 5px;
    padding-right: 5px;
  }
  .img-2,
  .img-3 {
    width: 559px;
    height: 412px;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1544px) {
  .img-1 {
    width: 636px;
    height: 948px;
  }
  .img-2,
  .img-3 {
    width: 636px;
    height: 468px;
  }
}

.lg-img {
  /* margin-top: inherit; */
  display: block;
  margin: auto;
}

/* styles for mobile browsers smaller than 576px; (iPhone) */
@media (max-width: 575px) {
  [id="1-white-pine-art"] {
    width: 374px;
    height: 207px;
  }

  [id="4-photo-shoot-art"] {
    width: 374px;
    height: 297px;
  }

  [id="1-white-pine-2-art"] {
    width: 374px;
    height: 191px;
  }

  [id="4-hunger-art"] {
    width: 374px;
    height: 278px;
  }
  [id="1-california-rt43-art"] {
    width: 374px;
    height: 250px;
  }

  [id="4-walls-of-arith-art"] {
    width: 374px;
    height: 250px;
  }

  [id="1-ars-fab-cover"] {
    width: 374px;
    height: 257px;
  }

  [id="3-ars-fab-spread"] {
    width: 374px;
    height: 131px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 374px;
    height: 370px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 374px;
    height: 365px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 374px;
    height: 342px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  [id="1-white-pine-art"] {
    width: 671px;
    height: 372px;
  }

  [id="4-photo-shoot-art"] {
    width: 671px;
    height: 533px;
  }

  [id="1-white-pine-2-art"] {
    width: 671px;
    height: 342px;
  }

  [id="4-hunger-art"] {
    width: 671px;
    height: 498px;
  }
  [id="1-california-rt43-art"] {
    width: 671px;
    height: 449px;
  }

  [id="4-walls-of-arith-art"] {
    width: 671px;
    height: 449px;
  }

  [id="1-ars-fab-cover"] {
    width: 671px;
    height: 462px;
  }

  [id="3-ars-fab-spread"] {
    width: 671px;
    height: 235px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 671px;
    height: 663px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 671px;
    height: 656px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 671px;
    height: 614px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}
/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  [id="1-white-pine-art"] {
    width: 992px;
    height: 550px;
  }

  [id="4-photo-shoot-art"] {
    width: 992px;
    height: 787px;
  }

  [id="1-white-pine-2-art"] {
    width: 992px;
    height: 506px;
  }

  [id="4-hunger-art"] {
    width: 992px;
    height: 736px;
  }
  [id="1-california-rt43-art"] {
    width: 992px;
    height: 664px;
  }

  [id="4-walls-of-arith-art"] {
    width: 992px;
    height: 664px;
  }

  [id="1-ars-fab-cover"] {
    width: 992px;
    height: 683px;
  }

  [id="3-ars-fab-spread"] {
    width: 992px;
    height: 347px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 992px;
    height: 980px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 992px;
    height: 969px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 992px;
    height: 908px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  [id="1-white-pine-art"] {
    width: 1200px;
    height: 665px;
  }

  [id="4-photo-shoot-art"] {
    width: 1200px;
    height: 952px;
  }

  [id="1-white-pine-2-art"] {
    width: 1200px;
    height: 612px;
  }

  [id="4-hunger-art"] {
    width: 1200px;
    height: 891px;
  }
  [id="1-california-rt43-art"] {
    width: 1200px;
    height: 803px;
  }

  [id="4-walls-of-arith-art"] {
    width: 1200px;
    height: 804px;
  }

  [id="1-ars-fab-cover"] {
    width: 1200px;
    height: 826px;
  }

  [id="3-ars-fab-spread"] {
    width: 1200px;
    height: 420px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 1075px;
    height: 1062px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 1075px;
    height: 1051px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 1075px;
    height: 984px;
  }
}

@media (min-width: 1521px) {
  [id="1-white-pine-art"] {
    width: 1420px;
    height: 787px;
  }

  [id="4-photo-shoot-art"] {
    width: 1420px;
    height: 1127px;
  }

  [id="1-white-pine-2-art"] {
    width: 1420px;
    height: 724px;
  }

  [id="4-hunger-art"] {
    width: 1420px;
    height: 1054px;
  }
  [id="1-california-rt43-art"] {
    width: 1420px;
    height: 950px;
  }

  [id="4-walls-of-arith-art"] {
    width: 1420px;
    height: 951px;
  }

  [id="1-ars-fab-cover"] {
    width: 1420px;
    height: 977px;
  }

  [id="3-ars-fab-spread"] {
    width: 1420px;
    height: 497px;
  }
  [id="1-cirque-du-celeste-1-art"] {
    width: 1075px;
    height: 1062px;
  }

  [id="4-cirque-du-celeste-2-art"] {
    width: 1075px;
    height: 1051px;
  }

  [id="5-cirque-du-celeste-3-art"] {
    width: 1075px;
    height: 984px;
  }
}

[href="/artproject3-hunger-text"],
[href="/artproject2-white-pine-1-text"],
[href="/artproject3-photo-shoot-text"],
[href="/artproject2-white-pine-2-text"],
[href="/artproject2-california-rt43-text"],
[href="/artprojectwalls-of-arith-text"],
[href="/artproject2-ars-fab-text"],
[href="/artproject2-cirque-du-celeste-text"],
[href="/artproject3-numeral-2"],
[href="/artproject6-numeral3"] {
  pointer-events: none;
  cursor: default;
}

button {
  padding: 0px !important;
}

@media (max-width: 575px) {
  a[href="/artproject1-white-pine-art"] {
    order: 1;
  }
  a[href="/artproject2-white-pine-text"] {
    order: 2;
  }
  a[href="/artproject/4-photo-shoot-art"] {
    order: 3;
  }
  a[href="/artproject3-photo-shoot-text"] {
    order: 4;
  }
  a[href="/artproject/1-white-pine-2-art"] {
    order: 5;
  }
  a[href="/artproject2-white-pine-2-text"] {
    order: 6;
  }
  a[href="/artproject/4-hunger-art"] {
    order: 7;
  }
  a[href="/artproject3-hunger-text"] {
    order: 8;
  }
  a[href="/artproject/1-california-rt43-art"] {
    order: 9;
  }
  a[href="/artproject2-california-rt43-text"] {
    order: 10;
  }
  a[href="/artproject/4-walls-of-arith-art"] {
    order: 11;
  }
  a[href="/artprojectwalls-of-arith-text"] {
    order: 12;
  }
  a[href="/artproject/1-ars-fab-cover"] {
    order: 13;
  }
  a[href="/artproject2-ars-fab-text"] {
    order: 14;
  }
  a[href="/artproject/3-ars-fab-spread"] {
    order: 15;
  }
  a[href="/artproject/1-cirque-du-celeste-1-art"] {
    order: 16;
  }
  a[href="/artproject2-cirque-du-celeste-text"] {
    order: 17;
  }
  a[href="/artproject/4-cirque-du-celeste-2-art"] {
    order: 18;
  }
  a[href="/artproject3-numeral-2"] {
    order: 19;
  }
  a[href="/artproject/5-cirque-du-celeste-3-art"] {
    order: 20;
  }
  a[href="/artproject/5-cirque-du-celeste-3-art"] {
    order: 21;
  }
  a[href="/artproject6-numeral3"] {
    order: 22;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
}

@media (max-width: 575px) {
  .left-info {
    width: 375px;
    margin-bottom: 10px;
  }
  .right-info {
    width: 375px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .left-info,
  .right-info {
    width: 350px;
    height: 522px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .left-info,
  .right-info {
    width: 370px;
    height: 552px;
  }
  .left-info {
    margin-right: 5px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .left-info,
  .right-info {
    width: 487px;
    height: 726px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .left-info,
  .right-info {
    width: 559px;
    height: 833px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .left-info,
  .right-info {
    width: 636px;
    height: 948px;
  }
}

/* can use app and index to override larger layout things on page */
.App {
  text-align: center;
}

.max-toolbar {
  max-width: 2600px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.logo-wide {
  margin: 0;
}

@media (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .logo-wide {
    width: 312px;
  }
}

@media (min-width: 481px) and (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .logo-wide {
    width: 400px;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
}
@media (min-width: 801px) and (max-width: 900px) {
}
@media (min-width: 901px) and (max-width: 1024px) {
}

@media (min-width: 1025px) and (max-width: 1366px) {
}

@media (min-width: 1367px) and (max-width: 1999px) {
}

@media (min-width: 2000px) and (max-width: 2082px) {
}

@media (min-width: 2083px) {
}

/* Document
 * ========================================================================== */

/**
 * 1. Correct the line height in all browsers.
 * 2. Prevent adjustments of font size after orientation changes in
 *    IE on Windows Phone and in iOS.
 */

html {
  line-height: 1.15; /* 1 */
  -ms-text-size-adjust: 100%; /* 2 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/* Sections
 * ========================================================================== */

/**
 * Correct the font size and margin on `h1` elements within `section` and
 * `article` contexts in Chrome, Edge, Firefox, and Safari.
 */

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/* Grouping content
 * ========================================================================== */

/**
 * Remove the margin on nested lists in Chrome, Edge, IE, and Safari.
 */

dl dl,
dl ol,
dl ul,
ol dl,
ul dl {
  margin: 0;
}

/**
 * Remove the margin on nested lists in Edge 18- and IE.
 */

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

/**
 * 1. Add the correct box sizing in Firefox.
 * 2. Show the overflow in Edge 18- and IE.
 */

hr {
  box-sizing: content-box; /* 1 */
  height: 0; /* 1 */
  overflow: visible; /* 2 */
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

pre {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/* Text-level semantics
 * ========================================================================== */

/**
 * Add the correct text decoration in Edge 18-, IE, and Safari.
 */

abbr[title] {
  text-decoration: underline;
  text-decoration: underline dotted;
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/* Embedded content
 * ========================================================================== */

/* Forms
 * ========================================================================== */

/**
 * Remove the margin on controls in Safari.
 */

button,
input,
select {
  margin: 0;
}

/**
 * 1. Show the overflow in IE.
 * 2. Remove the inheritance of text transform in Edge 18-, Firefox, and IE.
 */

button {
  text-transform: none; /* 2 */
}

/**
 * Correct the inability to style buttons in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * Show the overflow in Edge 18- and IE.
 */

input {
  overflow: visible;
}

/**
 * 1. Correct the text wrapping in Edge 18- and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 */

legend {
  box-sizing: border-box; /* 1 */
  display: table; /* 1 */
  max-width: 100%; /* 1 */
  white-space: normal; /* 1 */
}

/**
 * 1. Add the correct display in Edge 18- and IE.
 * 2. Add the correct vertical alignment in Chrome, Edge, and Firefox.
 */

progress {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
}

/**
 * Remove the inheritance of text transform in Firefox.
 */

select {
  text-transform: none;
}

/**
 * 1. Remove the margin in Firefox and Safari.
 * 2. Remove the default vertical scrollbar in IE.
 */

textarea {
  margin: 0; /* 1 */
}

/**
 * 1. Correct the odd appearance in Chrome, Edge, and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield; /* 1 */
  outline-offset: -2px; /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Safari.
 */

::-webkit-inner-spin-button,
::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Correct the text style of placeholders in Chrome, Edge, and Safari.
 */

::-webkit-input-placeholder {
  color: inherit;
  opacity: 0.54;
}

/**
 * Remove the inner padding in Chrome, Edge, and Safari on macOS.
 */

::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style upload buttons in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button; /* 1 */
  font: inherit; /* 2 */
}

/**
 * Remove the inner border and padding of focus outlines in Firefox.
 */

::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus outline styles unset by the previous rule in Firefox.
 */

:-moz-focusring {
  outline: 1px dotted ButtonText;
}

/**
 * Remove the additional :invalid styles in Firefox.
 */

:-moz-ui-invalid {
  box-shadow: none;
}

/* Interactive
 * ========================================================================== */

/*
 * Add the correct display in Edge 18- and IE.
 */

details {
  display: block;
}

/*
 * Add the correct styles in Edge 18-, IE, and Safari.
 */

dialog {
  background-color: white;
  border: solid;
  color: black;
  display: block;
  height: -moz-fit-content;
  height: -webkit-fit-content;
  height: fit-content;
  left: 0;
  margin: auto;
  padding: 1em;
  position: absolute;
  right: 0;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
}

dialog:not([open]) {
  display: none;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Scripting
 * ========================================================================== */

/* User interaction
 * ========================================================================== */

body {
  margin: 0;
  font-family: "Maven Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

