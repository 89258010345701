.toolbar {
  margin: 0 auto;
  top: 0;
  left: 0;
  width: 103%;
  background-color: white;
  height: 66px;
}

.toolbar_logo {
  margin-left: 1rem;
}

.toolbar_navigation {
  display: flex;
  height: 100%;
  align-items: center;
  padding: 0 1rem;
}

.toolbar_nav-items a {
  color: grey;
  text-decoration: none;
}

.toolbar_nav-items ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
}

.toolbar_nav-items li {
  padding: 0 1.2rem;
}

.toolbar_nav-items a:hover,
.toolbar_nav-items a:active {
  text-decoration: none;
  color: grey;
}

.spacer {
  flex: 1;
}

.design,
.art,
.info {
  font-family: "Maven Pro", sans-serif;
  font-size: 14pt;
  /* top: 12px; */
  letter-spacing: 0.2rem;
}

@media (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 255px !important;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (max-width: 767px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 307px !important;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 768px) and (max-width: 800px) {
  .toolbar_toggle-button {
    margin-left: 6px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 801px) and (max-width: 900px) {
  .toolbar_toggle-button {
    margin-left: 26px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}
@media (min-width: 901px) and (max-width: 1024px) {
  .toolbar_toggle-button {
    margin-left: 4px;
  }
  .toolbar_nav-items {
    display: none;
  }
  .m-logo {
    width: 460px;
    height: auto;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1025px) and (max-width: 1366px) {
  .toolbar {
    width: 90%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .toolbar_logo {
    margin-left: 0;
  }

  .design,
  .art,
  .info {
    font-family: "Maven Pro", sans-serif;
    font-size: 14pt;
    /* top: 12px; */
    letter-spacing: 0.2rem;
  }

  .m-logo {
    width: 592px;
    height: auto;
    margin-left: 7px;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 1367px) and (max-width: 1999px) {
  .toolbar {
    width: 84%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .toolbar_logo {
    margin-left: 0;
  }

  .design,
  .art,
  .info {
    font-family: "Maven Pro", sans-serif;
    font-size: 14pt;
    /* top: 12px; */
    letter-spacing: 0.2rem;
  }

  .m-logo {
    width: 592px;
    height: auto;
    margin-left: 7px;
    margin-bottom: 0px !important;
  }
}

@media (min-width: 2000px) and (max-width: 2082px) {
  .toolbar {
    width: 85%;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .m-logo {
    width: 636px;
    height: auto;
    margin-bottom: 0px !important;
    /* margin-left: 303px; */
  }
}

@media (min-width: 2083px) {
  .toolbar {
    max-width: 1404px;
  }
  .toolbar_nav-items {
    margin-left: 0px !important;
    margin-right: 27px;
  }
  .toolbar_toggle-button {
    display: none;
  }
  .m-logo {
    width: 636px;
    height: auto;
    margin-bottom: 0px !important;
    margin-left: 10px;
  }
}
