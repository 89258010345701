@media (max-width: 575px) {
  .left-info {
    width: 375px;
    margin-bottom: 10px;
  }
  .right-info {
    width: 375px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) {
  .left-info,
  .right-info {
    width: 350px;
    height: 522px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) {
  .left-info,
  .right-info {
    width: 370px;
    height: 552px;
  }
  .left-info {
    margin-right: 5px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .left-info,
  .right-info {
    width: 487px;
    height: 726px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .left-info,
  .right-info {
    width: 559px;
    height: 833px;
  }
}

@media (min-width: 2000px) {
  /* for sumo sized (mac) screens */
  .left-info,
  .right-info {
    width: 636px;
    height: 948px;
  }
}
