.full-w {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 480px) {
  /* styles for mobile browsers smaller than 480px; (iPhone) */
  .full-w {
    object-fit: cover;
    overflow: hidden;
    width: 698px !important;
    /* height: 813px !important;
    object-position: 50% -20px; */
  }
}

@media only screen and (min-width: 960px) {
  /* styles for browsers larger than 960px; */
  .full-w {
    width: 100%;
  }
}
@media only screen and (min-width: 1440px) {
  /* styles for browsers larger than 1440px; */
  .full-w {
    width: 100%;
    object-fit: cover;
    object-position: 12% -410px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1524px) {
  .full-w {
    width: 100%;
    object-position: 12% -530px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 1698px) {
  .full-w {
    width: 100%;
    object-position: 12% -600px;
    overflow: hidden;
  }
}

@media only screen and (min-width: 2000px) and (max-width: 2110px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -600px;
  }
}

@media only screen and (min-width: 2111px) and (max-width: 2456px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -700px;
  }
}

@media only screen and (min-width: 2457px) {
  /* for sumo sized (mac) screens */
  .full-w {
    width: 100%;
    object-position: 12% -750px;
  }
}
